
import page from "./page.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";

class payment {
	constructor(wrapper) {
		this.$wrapper = wrapper;
		console.log(this.$wrapper)
		this.init();
	}

	init() {
		this.setup_app();
	}

	setup_app() {
		// create a pinia instance
		let pinia = createPinia();

		// create a vue instance
		let app = createApp(page, { workflow: "987865453" });
		// SetVueGlobals(app);
		app.use(pinia);
		// app.component("MyComponent")
		// // mount the app
		app.mount(this.$wrapper.get(0));
	}
}

frappe.provide("frappe.mysys");
frappe.mysys.payment = payment;
console.log(payment)
export default payment;